import { z } from 'zod';
import { dateString } from '../../infrastructure';
import { JobStatuses } from './base';

export const TJobFamilySchema = z.object({
  job_family_id: z.number(),
  title: z.string(),
  job_category_id: z.number().int().nullable(),
  skill_role_id: z.number().int().nullable(),
});
export type TJobFamily = z.infer<typeof TJobFamilySchema>;

export const TJobSchema = z.object({
  job_id: z.string(), // ObjectId
  job_family_id: z.number().nullable(),
  slug: z.string(),
  owner_id: z.string().nullable(), // brains person_id
  job_suffix: z.string().trim().nullable(),
  title: z.string(), // Computed automatically by using job_family and suffix
  website_title: z.string().trim(),
  job_short_name: z.string().trim(),
  description: z.string(),
  n_sort: z.number().int(),
  on_deck_visibility: z.boolean(),
  max_allowed_salary: z.number(),
  executive_presence: z.boolean(),
  dev_skiller_test_id: z.string().nullable(),
  video_interview_audience: z.number().nullable(),
  department_id: z.string().nullable(),
  salary_min: z.number().nullable(),
  salary_max: z.number().nullable(),
  posted_salary_min: z.number().nullable(),
  posted_salary_max: z.number().nullable(),
  priority_id: z.number().int().nullable(),
  fast_decision_mode: z.boolean(),
  job_to_redirect_apps: z.string().nullable(),
  voice_note_required: z.boolean(),
  level: z.number().nullable(),
  english_level: z.number().nullable(),

  status: z.enum(JobStatuses),
  dt_passive: dateString.nullable(),
  dt_active: dateString.nullable(),
  dt_closed: dateString.nullable(),
  dt_archived: dateString.nullable(),

  created_by: z.string().nullable(),
  updated_by: z.string().nullable(),

  dtcreate: dateString,
  dtupdate: dateString.nullable(),
});

export type TJob = z.infer<typeof TJobSchema>;

export type TJobExtended = TJob & { category_name: string | null; owner_name: string | null };

export const TJobPrioritySchema = z.object({
  priority_id: z.number(),
  name: z.string(),
  priority: z.number(),
});
export type TJobPriority = z.infer<typeof TJobPrioritySchema>;

export const TJobCategorySchema = z.object({
  job_category_id: z.number(),
  name: z.string(),
  path: z.string(),
  level: z.number(),
  n_sort: z.number().nullable(),
  dtcreate: dateString,
  dtupdate: dateString,
});
export type TJobCategory = z.infer<typeof TJobCategorySchema>;

export const TJobEnglishLevelSchema = z.object({
  level_value: z.number(),
  description: z.string(),
});
export type TJobEnglishLevel = z.infer<typeof TJobEnglishLevelSchema>;

export const TJobLevelSchema = z.object({
  level_id: z.number(),
  title: z.string(),
  level_value: z.number(),
});
export type TJobLevel = z.infer<typeof TJobLevelSchema>;

export const TJobVideoInterviewAudienceSchema = z.object({
  audience_id: z.number(),
  title: z.string(),
});
export type TJobVideoInterviewAudience = z.infer<typeof TJobVideoInterviewAudienceSchema>;

export const TJobQuestionSchema = z.object({
  question_id: z.number(),
  mongo_id: z.string(),
  label: z.string(),
  description: z.string().nullable(),
  fields: z.record(z.string(), z.any()),
  info: z.string().nullable(),
  code: z.string(),
  created_by: z.string().nullable(),
  updated_by: z.string().nullable(),
  deleted_by: z.string().nullable(),
  dtcreate: dateString,
  dtupdate: dateString,
  dtdelete: dateString,
});
export type TJobQuestion = z.infer<typeof TJobQuestionSchema>;

export type JobQuestionSort = TJobQuestion & { sort: number };

export const TJobVideoQuestionSchema = z.object({
  video_question_id: z.number(),
  mongo_id: z.string(),
  name: z.string(),
  video_filename: z.string(),

  created_by: z.string().nullable(),
  updated_by: z.string().nullable(),
  deleted_by: z.string().nullable(),
  dtcreate: dateString,
  dtupdate: dateString,
  dtdelete: dateString,
});

export type TJobVideoQuestion = z.infer<typeof TJobVideoQuestionSchema>;

export type JobVideoQuestionSort = TJobVideoQuestion & { sort: number };

export const TJobVideoQuestionAnswerSchema = z.object({
  answer_id: z.number(),
  mongo_id: z.string(),
  application_id: z.string(),
  video_question_id: z.number(),
  video_question_name: z.string().nullish(),

  video_mp4: z.string(),
  thumb: z.string().nullable(),

  dtcreate: dateString,
  dtupdate: dateString,
});
export type TJobVideoQuestionAnswer = z.infer<typeof TJobVideoQuestionAnswerSchema>;

export const TJobQuestionAnswerSchema = z.object({
  answer_id: z.number(),
  application_id: z.string(),
  question_id: z.number(),

  answer: z.string().nullable(),

  dtcreate: dateString,
  dtupdate: dateString,
});
export type TJobQuestionAnswer = z.infer<typeof TJobQuestionAnswerSchema>;
