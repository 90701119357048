import { z } from 'zod';

export const SkillViewPaths = ['master', 'recruiting', 'sourcing'] as const;
export type SkillViewPath = (typeof SkillViewPaths)[number];

export const TSkillNodeSchema = z.object({
  skill_node_id: z.number(),
  skill_id: z.number().nullable(),

  label: z.string(),
  path: z.string(),
  level: z.number(),

  created_by: z.string().nullable(),
  updated_by: z.string().nullable(),

  dt_created: z.number(),
  dt_updated: z.number().nullable(),
  dt_archived: z.number().nullable(),
});

export type TSkillNode = z.infer<typeof TSkillNodeSchema>;

export const TSkillNodeCreateSchema = TSkillNodeSchema.pick({
  label: true,
  path: true,
}).extend({
  created_by: z.string().nullable().optional(),
  skill_id: z.number().nullish(),
});

export type TSkillNodeCreate = z.infer<typeof TSkillNodeCreateSchema>;

export const TSkillNodeUpdateSchema = TSkillNodeSchema.pick({
  label: true,
  path: true,
  dt_archived: true,
  updated_by: true,
}).partial();

export type TSkillNodeUpdate = z.infer<typeof TSkillNodeUpdateSchema>;

export const SkillNodeItemSchema = TSkillNodeSchema.extend({
  children: z.array(TSkillNodeSchema).optional(),
});

export type SkillNodeItem = z.infer<typeof SkillNodeItemSchema>;
