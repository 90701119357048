import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Select, SelectProps, Tag } from 'antd';
import { useSkillsByPath, useSkillsList } from '../../queries/skills/queries';
import { TSkill, TSkillNode, SkillNodeItem } from '@ct-internal/api';
import { SkillsByDepartmentResponse } from '@ct-internal/api';

interface SkillsSelectorProps {
  jobRoleId?: number;
  value?: number[];
  onChange?: SelectProps['onChange'];
}

function SkillsSelector({ jobRoleId, value = [], onChange }: SkillsSelectorProps) {
  const [searchText, setSearchText] = useState('');

  const { data: skillsByDepartment } = useSkillsByPath<SkillsByDepartmentResponse>({
    query: { path: 'recruiting' },
    options: { refetchOnWindowFocus: false },
  });

  const { data: skillsList } = useSkillsList({
    options: { refetchOnWindowFocus: false },
  });

  useEffect(() => {
    if (!jobRoleId || !skillsByDepartment?.skills) {
      return;
    }

    const bundle = skillsByDepartment.skills
      .flatMap((dept) => dept.children?.flatMap((subdept) => subdept.children || []) || [])
      .find((b) => b.skill_node_id === jobRoleId);

    if (bundle?.children) {
      const bundleSkills = bundle.children
        .map((skill) => skill.skill_id)
        .filter((id: number | null): id is number => id !== null);
      onChange?.(bundleSkills);
    }
  }, [onChange, jobRoleId, skillsByDepartment]);

  const skillNameMap = useMemo(() => {
    if (!skillsList?.skills) {
      return {};
    }

    return skillsList.skills.reduce((acc: Record<number, string>, skill: TSkill) => {
      acc[skill.skill_id] = skill.name;
      return acc;
    }, {});
  }, [skillsList]);

  const getBundleOptions = useCallback(
    (subdept: TSkillNode & { children?: Array<SkillNodeItem> }) => {
      return subdept.children
        ?.filter((bundle: SkillNodeItem) => {
          if (!searchText) {
            return true;
          }
          return bundle.label.toLowerCase().includes(searchText.toLowerCase());
        })
        .map((bundle: SkillNodeItem) => ({
          value: `bundle-${bundle.skill_node_id}`,
          label: bundle.label,
          isBundle: true,
          skills:
            bundle.children?.map((skill: SkillNodeItem) => skill.skill_id || 0).filter(Boolean) ||
            [],
        }));
    },
    [searchText],
  );

  const options = useMemo(() => {
    const allOptions: SelectProps['options'] = [];

    skillsByDepartment?.skills.forEach((dept) =>
      dept.children?.forEach((subdept) => {
        const deptOptions = getBundleOptions(subdept);
        if (deptOptions?.length) {
          allOptions.push({
            label: `${subdept.label} Bundles`,
            options: deptOptions,
          });
        }
      }),
    );

    if (searchText) {
      const skillOptions = (skillsList?.skills || [])
        .filter((skill) => skill.name.toLowerCase().includes(searchText.toLowerCase()))
        .map((skill) => ({
          value: `skill-${skill.skill_id}`,
          label: skill.name,
          isBundle: false,
          skillId: skill.skill_id,
        }));

      if (skillOptions.length > 0) {
        allOptions.push({
          label: 'Individual Skills',
          options: skillOptions,
        });
      }
    }

    return allOptions;
  }, [getBundleOptions, searchText, skillsByDepartment?.skills, skillsList?.skills]);

  const handleSelect = (selected: string) => {
    if (selected.startsWith('bundle-')) {
      const bundleId = parseInt(selected.replace('bundle-', ''));
      const bundle = skillsByDepartment?.skills
        .flatMap((dept) => dept.children?.flatMap((subdept) => subdept.children || []) || [])
        .find((b) => b.skill_node_id === bundleId);

      if (bundle?.children) {
        const bundleSkills = bundle.children
          .map((skill) => skill.skill_id)
          .filter((id: number | null): id is number => id !== null);
        onChange?.(Array.from(new Set([...value, ...bundleSkills])));
      }
    } else if (selected.startsWith('skill-')) {
      const skillId = parseInt(selected.replace('skill-', ''));
      onChange?.(Array.from(new Set([...value, skillId])));
    }
  };

  const handleDeselect = (selected: string) => {
    if (selected.startsWith('skill-')) {
      const skillId = parseInt(selected.replace('skill-', ''));
      onChange?.(value?.filter((id) => id !== skillId));
    }
  };

  const handleClear = () => {
    onChange?.([]);
  };

  const handleDropdownVisibleChange = (visible: boolean) => {
    if (!visible) {
      setSearchText('');
    }
  };

  const selectedValues = [...value.map((skillId) => `skill-${skillId}`)];

  return (
    <Select
      mode="multiple"
      showSearch
      allowClear
      style={{ width: '100%' }}
      placeholder="Select from pre-defined bundles or type to search individual skills"
      value={selectedValues}
      onSearch={setSearchText}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onClear={handleClear}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      options={options}
      optionFilterProp="label"
      data-testid="skills-selector"
      tagRender={({ label, value, closable, onClose }) => {
        if (!value) {
          return <Tag data-testid={`skill-tag-${value}`}>{label}</Tag>;
        }

        let displayLabel = label;
        if (value.startsWith('bundle-')) {
          const bundleId = parseInt(value.replace('bundle-', ''));
          const bundle = skillsByDepartment?.skills
            .flatMap((dept) => dept.children?.flatMap((subdept) => subdept.children || []) || [])
            .find((b) => b.skill_node_id === bundleId);
          displayLabel = bundle?.label || label;
        } else if (value.startsWith('skill-')) {
          const skillId = parseInt(value.replace('skill-', ''));
          displayLabel = skillNameMap[skillId] || label;
        }

        return (
          <Tag
            closable={closable}
            onClose={onClose}
            color="geekblue"
            style={{ marginRight: '8px', marginBottom: '4px' }}
            data-testid={`skill-tag-${value}`}
          >
            {displayLabel}
          </Tag>
        );
      }}
    />
  );
}

export default SkillsSelector;
