import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import APIRequest from '../../../utils/request';
import { ListSkillNodesEndpoint } from '@ct-internal/api';

import { SKILLS_NODE } from './keys';

// Queries
export const useSkillsNode = ({
  query,
  additionalQueryKeys = [],
  options,
}: {
  query?: ListSkillNodesEndpoint['query'];
  additionalQueryKeys?: any[];
  options?: UseQueryOptions<ListSkillNodesEndpoint['response']>;
}): UseQueryResult<ListSkillNodesEndpoint['response']> => {
  return useQuery({
    queryKey: [SKILLS_NODE, ...additionalQueryKeys],
    queryFn: () =>
      APIRequest(
        `/sql/skill-node`,
        {
          method: 'GET',
          qs: query,
          qsOptions: { arrayFormat: null },
          headers: {
            Accept: 'application/json',
          },
        },
        'hire-api',
      ),
    ...options,
  });
};
