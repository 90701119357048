import { z } from 'zod';
import { dateString } from '../../infrastructure';
import { ApplicationSchema } from '../application';

export enum ScrapingRequestType {
  'LinkedIn' = 'LinkedIn',
  'Glassdoor' = 'Glassdoor',
  'Indeed' = 'Indeed',
}
export enum ScrapingRequestStatus {
  'Pending' = 'Pending',
  'Processed' = 'Processed',
  'Failed' = 'Failed',
  'Skipped' = 'Skipped',
}

export const ScrapingRequestSchema = z.object({
  scraping_request_id: z.number(),
  application_id: z.string(),
  requested_by: z.string(),
  type: z.nativeEnum(ScrapingRequestType),
  result: z.record(z.any()),
  status: z.nativeEnum(ScrapingRequestStatus),
  dt_processed: dateString,
  dt_created: dateString,
  dt_updated: dateString,
});

export type ScrapingRequest = z.infer<typeof ScrapingRequestSchema>;

export const ScrapingRequestExtendedSchema = ScrapingRequestSchema.merge(
  ApplicationSchema.pick({ linkedin_url: true }),
);

export type ScrapingRequestExtended = z.infer<typeof ScrapingRequestExtendedSchema>;
