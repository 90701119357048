import { Endpoint, Method } from '../../../infrastructure';
import { ScrapingRequestSchema } from '../base';
import { z } from 'zod';

export const ScrapingRequestDeleteParamsSchema = ScrapingRequestSchema.pick({
  scraping_request_id: true,
});
export type ScrapingRequestDeleteParams = z.infer<typeof ScrapingRequestDeleteParamsSchema>;

export type ScrapingRequestDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/scraping-requests/${ScrapingRequestDeleteParams['scraping_request_id']}`,
  {},
  ScrapingRequestDeleteParams,
  undefined,
  undefined
>;
