import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TSkill, TSkillSchema } from '../base';

export const CreateSkillSchema = TSkillSchema.pick({
  name: true,
});

export type CreateSkillParams = {};

export type CreateSkillQuery = {};

export type CreateSkillRequest = z.infer<typeof CreateSkillSchema>;

export type CreateSkillResponse = TSkill;

export type CreateSkillEndpoint = Endpoint<
  Method.POST,
  '/api/v2/sql/skill',
  CreateSkillResponse,
  CreateSkillParams,
  CreateSkillQuery,
  CreateSkillRequest
>;
