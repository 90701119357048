import { z } from 'zod';
import { ScrapingRequestSchema } from '../base';

export const ScrapingRequestFindSchema = ScrapingRequestSchema.pick({
  scraping_request_id: true,
  application_id: true,
  status: true,
  type: true,
}).partial();
export type ScrapingRequestFind = z.infer<typeof ScrapingRequestFindSchema>;
