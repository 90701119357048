import { Endpoint, Method } from '../../../infrastructure';
import { ScrapingRequestSchema } from '../base';
import { ScrapingRequestUpdateSchema } from '../crud';
import { z } from 'zod';

export const ScrapingRequestUpdateRequestSchema = ScrapingRequestUpdateSchema.pick({
  status: true,
  dt_processed: true,
  result: true,
});
export type ScrapingRequestUpdateRequest = z.infer<typeof ScrapingRequestUpdateRequestSchema>;

export const ScrapingRequestUpdateParamsSchema = ScrapingRequestSchema.pick({
  scraping_request_id: true,
});
export type ScrapingRequestUpdateParams = z.infer<typeof ScrapingRequestUpdateParamsSchema>;

export const ScrapingRequestUpdateResponseSchema = ScrapingRequestSchema;
export type ScrapingRequestUpdateResponse = z.infer<typeof ScrapingRequestUpdateResponseSchema>;

export type ScrapingRequestUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/scraping-requests/${ScrapingRequestUpdateParams['scraping_request_id']}`,
  ScrapingRequestUpdateResponse,
  ScrapingRequestUpdateParams,
  undefined,
  ScrapingRequestUpdateRequest
>;
