import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

import { SkillNodeItem, SkillViewPaths, TSkillNode } from '../base';

export const ListSkillNodesQuerySchema = z.object({
  path: z.enum(SkillViewPaths).nullish(),
  level: z.coerce.number().optional(),
  minLevel: z.coerce.number().optional(),
  maxLevel: z.coerce.number().optional(),
});
export type ListSkillNodesQuery = z.infer<typeof ListSkillNodesQuerySchema>;

export type ListSkillNodesParams = {};

export type ListSkillNodesRequest = {};

export type ListSkillNodesResponse = { skills: SkillNodeItem[] };

export type SkillNodeWithChildren = SkillNodeItem & {
  children?: SkillNodeWithChildren[];
};

export type SkillTreeStructure = {
  skills: Array<SkillNodeWithChildren>;
};

export type SkillsByDepartmentResponse = {
  skills: Array<
    TSkillNode & {
      children?: Array<
        TSkillNode & {
          children?: Array<
            TSkillNode & {
              children?: Array<SkillNodeItem>;
            }
          >;
        }
      >;
    }
  >;
};

export type ListSkillNodesEndpoint = Endpoint<
  Method.POST,
  '/api/v2/sql/skill-node',
  ListSkillNodesResponse,
  ListSkillNodesParams,
  ListSkillNodesQuery,
  ListSkillNodesRequest
>;
