import { Link } from 'react-router-dom';
import { Flex, Typography } from 'antd';
import { NotificationConfigItem, NotificationConfigMessage } from '.';

export const InterviewWithoutDecisionMessage: NotificationConfigMessage = (notification) => {
  return `Interview with ${
    notification.metadata?.applicant_name ?? ''
  } already passed 5 days, but not decision was made to the application.`;
};

export const InterviewWithoutDecision: NotificationConfigItem = {
  title: 'Interview without decision',
  getDescription: (notification) =>
    notification.metadata && (
      <Flex gap={8} vertical>
        <Typography.Text>
          The application of{' '}
          <Link
            target="_blank"
            to={`/admin/application/${notification.metadata.application_id}`}
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            {notification.metadata.applicant_name as string}
          </Link>{' '}
          has 2nd interview more than 5 days ago, but not decision was made to the application.
        </Typography.Text>
      </Flex>
    ),
};
