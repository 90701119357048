import { z } from 'zod';

export const TSkillSchema = z.object({
  skill_id: z.number(),
  name: z.string(),

  created_by: z.string().nullable(),
  updated_by: z.string().nullable(),

  dt_created: z.number(),
  dt_updated: z.number(),
  dt_archived: z.number().nullable(),
});

export type TSkill = z.infer<typeof TSkillSchema>;

export const TSkillCreateSchema = z.object({
  name: z.string(),
  created_by: z.string().nullish(),
});

export type TSkillCreate = z.infer<typeof TSkillCreateSchema>;

export const TSkillUpdateSchema = z.object({
  name: z.string().optional(),
  updated_by: z.string().nullish(),
  dt_updated: z.number().optional(),
  dt_archived: z.number().optional(),
});

export type TSkillUpdate = z.infer<typeof TSkillUpdateSchema>;

export const TSkillDeleteSchema = z.object({
  updated_by: z.string().nullish(),
});

export type TSkillDelete = z.infer<typeof TSkillDeleteSchema>;
