import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

import { TSkillNode, SkillViewPaths } from '../base';

export const ListSkillNodesSlimQuerySchema = z
  .object({
    parentPath: z.string(),
    path: z.enum(SkillViewPaths).nullish(),
    level: z.coerce.number(),
    minLevel: z.coerce.number(),
    maxLevel: z.coerce.number(),
  })
  .partial()
  .optional();
export type ListSkillNodesSlimQuery = z.infer<typeof ListSkillNodesSlimQuerySchema>;

export type SkillNodeSlim = {
  skill_node_id: TSkillNode['skill_node_id'];
  label: TSkillNode['label'];
  path: TSkillNode['path'];
};

export type ListSkillNodesSlimResponse = SkillNodeSlim[];

export type ListSkillNodesSlimEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/skill-node/slim',
  ListSkillNodesSlimResponse,
  undefined,
  ListSkillNodesSlimQuery,
  undefined
>;
