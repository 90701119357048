import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TAppSkillAscSchema } from '../base';

export const AppSkillAscSchema = TAppSkillAscSchema.pick({
  skill_id: true,
  level: true,
  is_favorite: true,
}).extend({
  name: z.string(),
});

export type AppSkillAsc = z.infer<typeof AppSkillAscSchema>;

export type ListAppSkillAscParams = {
  application_id: string;
};

export type ListAppSkillAscQuery = {};

export type ListAppSkillAscRequest = {};

export type ListAppSkillAscResponse = AppSkillAsc[];

export type ListAppSkillAscEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql/applications/skills/${ListAppSkillAscParams['application_id']}`,
  ListAppSkillAscResponse,
  ListAppSkillAscParams,
  ListAppSkillAscQuery,
  ListAppSkillAscRequest
>;
