import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TSkill, TSkillSchema } from '../base';

export const UpdateSkillSchema = TSkillSchema.pick({
  name: true,
});

export const UpdateSkillParamsSchema = z.object({
  skill_id: z.string().regex(/^\d+$/, 'Invalid skill id').transform(Number),
});

export type UpdateSkillParams = z.infer<typeof UpdateSkillParamsSchema>;

export type UpdateSkillQuery = {};

export type UpdateSkillRequest = z.infer<typeof UpdateSkillSchema>;

export type UpdateSkillResponse = TSkill;

export type UpdateSkillEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql/skill/${UpdateSkillParams['skill_id']}`,
  UpdateSkillResponse,
  UpdateSkillParams,
  UpdateSkillQuery,
  UpdateSkillRequest
>;
