import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TAppSkillAscSchema } from '../base';

export const AppSkillGroupedByParentSchema = z.object({
  title: z.string(),
  children: z.array(
    TAppSkillAscSchema.pick({
      skill_id: true,
      level: true,
      is_favorite: true,
    }).extend({
      name: z.string(),
      parent: z.string(),
    }),
  ),
});
export type AppSkillGroupedByParent = z.infer<typeof AppSkillGroupedByParentSchema>;

export type ListAppSkillGroupedByParentParams = {
  application_id: string;
};

export type ListAppSkillGroupedByParentQuery = {};

export type ListAppSkillGroupedByParentRequest = {};

export type ListAppSkillGroupedByParentResponse = AppSkillGroupedByParent[];

export type ListAppSkillGroupedByParentEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql/applications/skills/${ListAppSkillGroupedByParentParams['application_id']}/grouped-by-parent`,
  ListAppSkillGroupedByParentResponse,
  ListAppSkillGroupedByParentParams,
  ListAppSkillGroupedByParentQuery,
  ListAppSkillGroupedByParentRequest
>;
