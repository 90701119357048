import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { PsnDeepSkillSchema } from '../';
import { PtoSummary } from '../pto';

export const PersonSchema = z.object({
  person_id: z.string(),
  email: z.string().nullable(),
  firstname: z.string().nullable(),
  surname: z.string().nullable(),
  department: z.string(),
  hiredfor: z.string(),
  dthired: dateString.nullable(),
  dtstart: dateString.nullable(),
  dtterminated: dateString.nullable(),
  dtcreate: dateString,
  dtupdate: dateString.nullable(),
  latest_contract_id: z.string().nullable(),
  isdeleted: z.boolean().default(false).nullable(),
  name: z.string().nullable(),
  timezone: z.string().nullable(),
  culture_contact_id: z.string().nullable(),
  person_sf_id: z.string().nullable(),
  immigration_program: z.string().nullable(),
  current_salary: z.number().nullable(),
  slack_id: z.string().nullable(),
  default_vacation_alloc: z.number(),
  gravatar_url: z.string().nullable(),
  birthday: dateString.nullable(),
  bio: z.string().nullable(),
  dev_bio: z.string().nullable(),
  t_shirt_size: z.string().nullable(),
});
export type Person = z.infer<typeof PersonSchema>;

export const PersonMongoSchema = z.object({
  _id: z.string(),
  gravatarURL: z.string(),
  name: z.string(),
  email: z.string(),
  salary: z.number(),
  timezoneId: z.string().nullish(),
  timezone: z.string().nullish(),
  timezoneShort: z.string().nullish(),
  timezoneEST: z.string().nullish(),
  timezoneShortEST: z.string().nullish(),
  clevertimeId: z.string().nullish(),
  hireDate: dateString,
  hirePeriods: z
    .array(
      z.object({
        startDate: dateString,
        endDate: dateString,
        payThroughDate: dateString,
      }),
    )
    .nullish(),
  terminationDate: dateString,
  isBillable: z.boolean().default(false),
  isHourlyBillable: z.boolean().default(false),
  department: z.string().nullable(),
  role: z.string().nullish(),
  shortName: z.string().nullish(),
  isAvailableNow: z.boolean().nullish(),
  sort: z.string().nullish(),
  linkedinURL: z.string().nullish(),
  cleverHireID: z.string().nullish(),
  activeTags: z.array(z.string()).default([]),
  publicSkills: z.array(PsnDeepSkillSchema),
  salaries: z.array(
    z.object({
      startDate: dateString,
      amount: z.number(),
      currency: z.string(),
      author: z.string(),
      foreignAmount: z.number(),
      foreignCurrency: z.string(),
    }),
  ),
  birthday: dateString.nullish(),
});

export type PersonMongo = z.infer<typeof PersonMongoSchema>;

export type TimeOffSummary = PtoSummary & {
  unpaidUsed: number;
};

export type TimeOffSummaryHistoryItem = {
  year: number;
  summary: TimeOffSummary;
};

export type PtoBreakdown = {
  vacationAlloc: number;
  seniorityExtra: number;
  manualAdjustment: number;
};

export type CarryoverStatus = {
  canUseCarryover: boolean;
  maxDateToUseCarryover: string | null;
};

export const ERROR_PERSON_NOT_FOUND = 'Person not found.';
