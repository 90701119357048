import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';

export const DeleteSkillParamsSchema = z.object({
  skill_id: z.string().regex(/^\d+$/, 'Invalid skill id').transform(Number),
});

export type DeleteSkillParams = z.infer<typeof DeleteSkillParamsSchema>;

export type DeleteSkillQuery = {};

export type DeleteSkillRequest = {};

export type DeleteSkillResponse = {};

export type DeleteSkillEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/sql/skill/${DeleteSkillParams['skill_id']}`,
  DeleteSkillResponse,
  DeleteSkillParams,
  DeleteSkillQuery,
  DeleteSkillRequest
>;
