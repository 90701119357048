import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TSkill } from '../../skill-v2/base';

export const ListJobFamilySkillsParamsSchema = z.object({
  jobFamilyId: z.coerce.number().int(),
});

export type ListJobFamilySkillsParams = z.infer<typeof ListJobFamilySkillsParamsSchema>;

export type ListJobFamilySkillsResponseItem = Pick<TSkill, 'skill_id' | 'name'>;
export type ListJobFamilySkillsResponse = ListJobFamilySkillsResponseItem[];

export type ListJobFamilySkillsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/job-families/:jobFamilyId/skills',
  ListJobFamilySkillsResponse,
  ListJobFamilySkillsParams,
  undefined,
  undefined
>;
