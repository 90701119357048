import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TJobFamilySchema } from '../table-schemas';

export const CreateJobFamilyRequestSchema = z.object({
  title: z.string(),
  skill_role_id: z.number().optional(),
});

export type CreateJobFamilyRequest = z.infer<typeof CreateJobFamilyRequestSchema>;

export const CreateJobFamilyResponseSchema = z.object({
  job_family_id: TJobFamilySchema.shape.job_family_id,
});
export type CreateJobFamilyResponse = z.infer<typeof CreateJobFamilyResponseSchema>;

export type CreateJobFamilyEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql/job-families`,
  CreateJobFamilyResponse,
  undefined,
  undefined,
  CreateJobFamilyRequest
>;
