import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const JobWebsiteListItemSchema = z.object({
  _id: z.string(),
  slug: z.string(),
  description: z.string(),
  name: z.string(),
  skills: z.string().array(),
  skill_role_id: z.number().nullable(),
});
export type JobWebsiteListItem = z.infer<typeof JobWebsiteListItemSchema>;

export const JobWebsiteListQuerySchema = z.object({
  page: z.number().optional(),
  limit: z.number().optional(),
});
export type JobWebsiteListQuery = z.infer<typeof JobWebsiteListQuerySchema>;

export type JobWebsiteListResponse = {
  meta: {
    page: number;
    limit: number;
    total: number;
  };
  data: JobWebsiteListItem[];
};

export type JobWebsiteListEndpoint = Endpoint<
  Method.GET,
  '/api/v2/website/jobs',
  JobWebsiteListResponse,
  undefined,
  JobWebsiteListQuery,
  undefined
>;
