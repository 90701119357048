import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TSkillNode, TSkillNodeSchema } from '../base';

export const CreateSkillNodeSchema = TSkillNodeSchema.pick({
  label: true,
}).extend({
  parent_skill_node_id: z.number().optional(),
  skills: z.array(z.number()).optional(),
  is_skill: z.boolean().optional(),
});

export type CreateSkillNodeParams = {};

export type CreateSkillNodeQuery = {};

export type CreateSkillNodeRequest = z.infer<typeof CreateSkillNodeSchema>;

export type CreateSkillNodeResponse = Omit<TSkillNode, 'level'>;

export type CreateSkillNodeEndpoint = Endpoint<
  Method.POST,
  '/api/v2/sql/skill-node',
  CreateSkillNodeResponse,
  CreateSkillNodeParams,
  CreateSkillNodeQuery,
  CreateSkillNodeRequest
>;
