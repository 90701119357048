import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import APIRequest from '../../utils/request';
import { SkillEndpoints, SkillNodeEndpoints } from '@ct-internal/api';

import { SKILLS_BY_PATH, SKILLS_LIST } from './keys';

// Queries
export const useSkillsByPath = <T extends SkillNodeEndpoints['list']['response']>({
  additionalQueryKeys = [],
  query,
  options,
}: {
  additionalQueryKeys?: any[];
  query?: SkillNodeEndpoints['list']['query'];
  options?: UseQueryOptions<T>;
}): UseQueryResult<T> => {
  const path = query?.path;

  return useQuery({
    queryKey: [SKILLS_BY_PATH, path, ...additionalQueryKeys],
    queryFn: () =>
      APIRequest(
        `/sql/skill-node?path=${path}`,
        {
          method: 'GET',
          qsOptions: { arrayFormat: null },
          headers: {
            Accept: 'application/json',
          },
        },
        'hire-api',
      ),
    ...options,
  });
};

export const useSkillsList = ({
  additionalQueryKeys = [],
  options,
}: {
  additionalQueryKeys?: any[];
  options?: UseQueryOptions<SkillEndpoints['list']['response']>;
}): UseQueryResult<SkillEndpoints['list']['response']> => {
  return useQuery({
    queryKey: [SKILLS_LIST, ...additionalQueryKeys],
    queryFn: () =>
      APIRequest(
        `/sql/skill`,
        {
          method: 'GET',
          qsOptions: { arrayFormat: null },
          headers: {
            Accept: 'application/json',
          },
        },
        'hire-api',
      ),
    ...options,
  });
};
