import React from 'react';
import { Select, SelectProps } from 'antd';

import { AppSkillAsc, SkillNodeItem } from '@ct-internal/api';
import { useSkillsNode } from './query/queries';

type SkillWithParent = SkillNodeItem & { parent?: string | boolean };

type ParseSkillsToGroupDataProps = {
  skills?: SkillNodeItem[];
  filterOut?: AppSkillAsc[];
};

const parseSkillsToGroupData = ({
  skills,
  filterOut,
}: ParseSkillsToGroupDataProps): SelectProps['options'] => {
  const findSkillParent = (skillNodeId: number, skills?: SkillNodeItem[]): string | boolean => {
    let parent: SkillNodeItem | undefined;
    let skill: string | boolean = !!skills?.find((sk) => sk.skill_node_id === skillNodeId);
    if (!skill) {
      skills?.forEach((sk) => {
        if (!skill) {
          skill = findSkillParent(skillNodeId, sk.children);
          if (skill && typeof skill === 'boolean') {
            parent = sk;
          }
        }
      });
    }
    return parent?.label ?? skill;
  };

  const getSlimSkills = (current?: SkillNodeItem[]): SkillWithParent[] => {
    const slim: SkillWithParent[] = [];

    current?.forEach((skill) => {
      slim.push({ ...skill, parent: findSkillParent(skill.skill_node_id, skills) });
      if (skill.children) {
        slim.push(...(getSlimSkills(skill.children) || []));
      }
    });

    return slim;
  };

  const skillsWithParent = getSlimSkills(skills);

  const groupedByParent = skillsWithParent
    .filter((skill) => !filterOut || !filterOut.some((sk) => sk.skill_id === skill.skill_node_id))
    .reduce((acc: { [key: string]: SkillNodeItem[] }, sk) => {
      if (typeof sk.parent === 'string' && sk.skill_id) {
        if (acc[sk.parent]) {
          acc[sk.parent].push(sk);
        } else {
          acc[sk.parent] = [sk];
        }
      }
      return acc;
    }, {});

  return Object.entries(groupedByParent)
    .map(([parent, children]) => ({
      label: parent,
      title: parent,
      options: children
        .map((skill) => ({
          key: `${parent}-${skill.skill_id}`,
          value: skill.skill_id,
          label: skill.label,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};

export type SkillSelectView = 'master' | 'recruiting' | 'sourcing';

export interface SkillSelectProps extends SelectProps {
  view?: SkillSelectView;
  filterOut?: AppSkillAsc[];
}

function SelectSkills({ view = 'master', filterOut, placeholder, ...props }: SkillSelectProps) {
  const { data, isLoading } = useSkillsNode({
    query: { path: view },
    options: { refetchOnWindowFocus: false },
    additionalQueryKeys: [view],
  });

  return (
    <Select
      showSearch
      allowClear
      loading={isLoading}
      mode="multiple"
      placeholder={placeholder ?? 'Skills'}
      optionFilterProp="label"
      options={parseSkillsToGroupData({
        skills: data?.skills,
        filterOut,
      })}
      {...props}
    />
  );
}

export default SelectSkills;
