import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { PersonSchema } from '../../people';

export const GetPersonInfoSlimQuerySchema = z.object({
  email: z.string(),
});
export type GetPersonInfoSlimQuery = z.infer<typeof GetPersonInfoSlimQuerySchema>;

export const GetPersonInfoSlimResponseSchema = PersonSchema.pick({
  person_id: true,
  birthday: true,
  bio: true,
  dev_bio: true,
  t_shirt_size: true,
  slack_id: true,
  name: true,
  email: true,
  dthired: true,
});

export type GetPersonInfoSlimResponse = z.infer<typeof GetPersonInfoSlimResponseSchema>;

export type GetPersonInfoSlimEndpoint = Endpoint<
  Method.GET,
  '/api/coach-ai/person-info',
  GetPersonInfoSlimResponse,
  {},
  GetPersonInfoSlimQuery,
  {}
>;
