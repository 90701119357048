import { z } from 'zod';

import { TJobSchema } from '../jobs';
import { TSkillSchema } from '../skill-v2';

export const JobSkillAscSchema = TJobSchema.pick({
  job_id: true,
}).merge(
  TSkillSchema.pick({
    skill_id: true,
  }),
);

export type JobSkillAsc = z.infer<typeof JobSkillAscSchema>;
