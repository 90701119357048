import { z } from 'zod';
import { ScrapingRequestSchema } from '../base';

export const ScrapingRequestUpdateSchema = ScrapingRequestSchema.pick({
  scraping_request_id: true,
  result: true,
  dt_processed: true,
  status: true,
}).partial({
  dt_processed: true,
  result: true,
  status: true,
});
export type ScrapingRequestUpdate = z.infer<typeof ScrapingRequestUpdateSchema>;
