import { z } from 'zod';

import { Endpoint, Method, makeSorter, FilterOperations } from '../../../infrastructure';

import { AppDetailSchema } from '../../application';

const ApplicantExpandedSchema = AppDetailSchema.pick({
  application_id: true,
  contract_status: true,
  name: true,
  email: true,
  availability: true,
  seniority: true,
  english_level: true,
  yoe: true,
  status: true,
  dt_applied: true,
  excitement_rate: true,
  country: true,
  country_code: true,
  city: true,
  state: true,
  timezone: true,
  timezone_number: true,
  timezone_short: true,
  latitude: true,
  longitude: true,
  expected_contract_type: true,
}).extend({
  match_score: z.number(),
  mgmt_reviewed: z.boolean(),
  is_gem: z.boolean(),
  is_stale: z.boolean(),
  is_aged: z.boolean(),
  is_exact_match: z.boolean(),
  is_close_match: z.boolean(),
  is_partial_match: z.boolean(),
  days_passed: z.number(),
  dtupdate: z.string().nullable(),
  last_update: z.string().nullable(),
  dt_update_interview: z.string().nullable(),
  dt_update_comment: z.string().nullable(),
  summary: z
    .object({
      comment_id: z.number(),
      comment: z.string(),
    })
    .nullable(),
  recruiter: z
    .object({
      applicant_id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  author: z
    .object({
      applicant_id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  job: z
    .object({
      job_id: z.string(),
      job_name: z.string(),
      description: z.string(),
    })
    .nullable(),
  current_stage: z.string().nullable(),
});

export const OnDeckCandidateInPipelineSchema = z.object({
  job_id: z.string(),
  job_suffix: z.string(),
  job_level: z.string(),
  job_status: z.string(),
  job_slug: z.string(),
  job_title: z.string(),
  job_family_id: z.number(),
  job_category_name: z.string(),
  job_family_title: z.string(),
  missing_summaries: z.number(),

  applications: z.array(
    z.object({
      application_id: z.string(),
      job_id: z.string(),
      recruiter_id: z.string(),
      recruiter_name: z.string(),
      author_id: z.string().nullable(),
      cost: z.number().nullable(),
      usd_salary: z.number().nullable(),
      currency: z.string().nullable(),
      currency_cost: z.number().nullable(),
      currency_salary: z.number().nullable(),
      stage: z.number().nullable(),
      app_detail: ApplicantExpandedSchema,
      name: z.string(),
      match_score: z.number(),
    }),
  ),
});

export type OnDeckCandidateInPipelineItem = z.infer<typeof OnDeckCandidateInPipelineSchema>;

export const OnDeckCandidatesInPipelineFiltersSchema = z
  .object({
    is_senior: z.record(z.enum([FilterOperations.EQUAL]), z.enum(['true', 'false'])),
    is_tech_lead: z.record(z.enum([FilterOperations.EQUAL]), z.enum(['true', 'false'])),
    is_solutions_architect: z.record(z.enum([FilterOperations.EQUAL]), z.enum(['true', 'false'])),
    is_gem: z.record(z.enum([FilterOperations.EQUAL]), z.enum(['true', 'false'])),
    recruiter_id: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    stale_max_days: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()),
    salary_max: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()),
  })
  .partial();

export type OnDeckCandidatesInPipelineFilters = z.infer<
  typeof OnDeckCandidatesInPipelineFiltersSchema
>;

export const OnDeckCandidatesInPipelineSorterSchema = makeSorter(
  z.enum(['job_family_title', 'job_category_name', 'missing_summaries']),
);

export type OnDeckCandidatesInPipelineSorter = z.infer<
  typeof OnDeckCandidatesInPipelineSorterSchema
>;

export const OnDeckCandidatesInPipelineQuerySchema = z.object({
  filters: OnDeckCandidatesInPipelineFiltersSchema.optional(),
  sorter: OnDeckCandidatesInPipelineSorterSchema.optional(),
});

export type OnDeckCandidatesInPipelineParams = {};

export type OnDeckCandidatesInPipelineQuery = z.infer<typeof OnDeckCandidatesInPipelineQuerySchema>;

export type OnDeckCandidatesInPipelineResponse = OnDeckCandidateInPipelineItem[];

export type OnDeckCandidatesInPipelineEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/on-deck/candidates-in-pipeline',
  OnDeckCandidatesInPipelineResponse,
  OnDeckCandidatesInPipelineParams,
  OnDeckCandidatesInPipelineQuery,
  {}
>;
