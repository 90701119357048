import { z } from 'zod';
import { ScrapingRequestSchema } from '../base';

export const ScrapingRequestCreateSchema = ScrapingRequestSchema.pick({
  application_id: true,
  requested_by: true,
  type: true,
}).partial({
  requested_by: true,
});
export type ScrapingRequestCreate = z.infer<typeof ScrapingRequestCreateSchema>;
